import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_layout_footer = _resolveComponent("a-layout-footer")!

  return (_openBlock(), _createBlock(_component_a_layout_footer, { style: {"text-align":"center"} }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("span", null, "欢迎：" + _toDisplayString(_ctx.user.name), 513), [
        [_vShow, _ctx.user.id]
      ]),
      _createTextVNode(" Wiki ©2024 Created by JamYoung ")
    ]),
    _: 1
  }))
}