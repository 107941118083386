import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tree = _resolveComponent("a-tree")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, null, {
    default: _withCtx(() => [
      _createVNode(_component_a_layout_content, { style: { background: '#fff', padding: '24px', margin: 0, minHeight: '280px'} }, {
        default: _withCtx(() => [
          (_ctx.level1.length === 0)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_1, "对不起，找不到相关文件！"))
            : _createCommentVNode("", true),
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 6 }, {
                default: _withCtx(() => [
                  (_ctx.level1.length > 0)
                    ? (_openBlock(), _createBlock(_component_a_tree, {
                        key: 0,
                        "tree-data": _ctx.level1,
                        onSelect: _ctx.onSelect,
                        replaceFields: {title: 'name', key: 'id', value: 'id'},
                        "default-expand-all": true,
                        "：default-selected-keys": "defaultSelectedKeys"
                      }, null, 8, ["tree-data", "onSelect"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 18 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "wangeditor",
                    innerHTML: _ctx.html
                  }, null, 8, _hoisted_2)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}