<template>
  <a-layout>
    <a-layout-content
        :style="{ background: '#fff', padding: '24px', margin: 0, minHeight: '280px' }"
    >
      <p>
        <a-form
            layout="inline"
            :model="param"
        >
          <a-form-item>
            <a-input v-model:value="tagReq" placeholder="标签名"></a-input>
          </a-form-item>
          <a-form-item>
            <a-button
                type="primary"
                @click="handleQuery({page: 1,size: pagination.pageSize})"
                size="large"
            >
              查询
            </a-button>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="add()" size="large">
              新增
            </a-button>
          </a-form-item>
        </a-form>
      </p>
      <a-table
          :columns="columns"
          :row-key="record => record.id"
          :data-source="tags"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
      >
        <template v-slot:action="{ text, record }">
          <a-space size="small">

<!--            <a-button type="primary" @click="setEbookTag(record)">-->
<!--              电子书标签-->
<!--            </a-button>-->
            <a-button type="primary" @click="edit(record)">
              编辑
            </a-button>
            <a-popconfirm
                title="删除后不可恢复，确认删除？"
                ok-text="是"
                cancel-text="否"
                @confirm="del(record.tagId)"
            >
              <a-button danger>
                删除
              </a-button>
            </a-popconfirm>
          </a-space>
        </template>
      </a-table>
    </a-layout-content>
  </a-layout>
  <a-modal v-model:open="modalVisible"
           title="标签表单"
           :confirm-loading="modalLoading"
           @ok="handleModalOk">
    <a-form :model="tag" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
      <a-form-item label="标签名">
        <a-input v-model:value="tag.title"/>
      </a-form-item>
<!--      <a-form-item label="创建者id">-->
<!--        <a-input v-model:value="tag.creatorId"/>-->
<!--      </a-form-item>-->
<!--      <a-form-item label="标签id" v-show="!tag.tagId">-->
<!--        <a-input v-model:value="tag.tagId"/>-->
<!--      </a-form-item>-->
    </a-form>
  </a-modal>



<!--  <a-modal v-model:open="ebookTagModalVisible"-->
<!--           title="电子书标签"-->
<!--           :confirm-loading="ebookTagModalLoading"-->
<!--           @ok="handleEbookTagModalOk">-->
<!--    <a-form :model="tag" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">-->
<!--      <a-form-item label="标签">-->
<!--        <a-input v-model:value="tag.tagId"/>-->
<!--      </a-form-item>-->
<!--    </a-form>-->
<!--  </a-modal>-->

</template>


<script lang="ts">
import { defineComponent,onMounted,ref } from 'vue';
import axios from 'axios';
import { message } from "ant-design-vue";
import {Tool} from "../../../util/tool";
import store from "@/store";


export default defineComponent({
  name: 'AdminTag',
  setup() {
    const param = ref();
    param.value = {};
    const tags = ref();
    const pagination = ref({
      current: 1,
      pageSize: 6,
      total: 0
    });
    const loading = ref(false);

    const columns = [
      {
        title: '标签名',
        dataIndex: 'title',
      },
      {
        title: '创建者',
        dataIndex: 'creatorName',
      },
      {
        title: '标签id',
        dataIndex: 'tagId',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        slots: { customRender: 'action' }
      }
    ];

    /**
     * 数据查询
     */
    const tagReq = ref();
    const handleQuery = (params: any) => {
      loading.value = true;
      // tags.value = [];
      axios.get("/tag/list", {
        params:{
          page: params.page,
          size: params.size,
          title: tagReq.value
        }
      }).then((response) => {
        loading.value = false;
        const data = response.data;
        if(data.success) {
          tags.value = data.content.list;

          // 重置分页按钮
          pagination.value.current = params.page;
          pagination.value.total = data.content.total;
        }else{
          message.error(data.message);
        }
      });
    };

    /**
     * 表格点击页码时触发
     */
    const handleTableChange = (pagination: any) => {
      // console.log("看看自带的分页参数都有啥；" + pagination);
      handleQuery({
        page: pagination.current,
        size: pagination.pageSize
      });
    };

    // -------- 表单 --------
    const tag = ref();
    const modalVisible = ref<boolean>(false);
    const modalLoading = ref<boolean>(false);
    const handleModalOk = () => {
      modalLoading.value = true;

      tag.value.creatorId = store.state.user.id;
      axios.post("/tag/save", tag.value).then((response) => {
        modalLoading.value = false;
        const data = response.data; // data = commonResp
        if (data.success) {
          modalVisible.value = false;

          // 重新查询
          handleQuery({
            page: pagination.value.current,
            size: pagination.value.pageSize
          });
        }else {
          message.error(data.message);
        }
      });
    };

    // -------- 添加标签 --------

    // const tagModalVisible = ref<boolean>(false);
    // const tagModalLoading = ref<boolean>(false);
    // const handleTagModalOk = () => {
    //   tagModalLoading.value = true;
    //
    //   axios.post("/tag/user", tag.value).then((response) => {
    //     tagModalLoading.value = false;
    //     const data = response.data; // data = commonResp
    //     if (data.success) {
    //       tagModalVisible.value = false;
    //     }else {
    //       message.error(data.message);
    //     }
    //   });
    // };

    const edit = (record: any) => {
      modalVisible.value = true;
      tag.value = Tool.copy(record);
    };

    const add = () => {
      modalVisible.value = true;
      tag.value = {};
    };

    const del = (tagId: number) => {
      // console.log(tagId);
      axios.delete("/tag/delete/" + tagId).then((response) => {
        const data = response.data; // data = commonResp
        if (data.success) {
          // 重新查询
          handleQuery({
            page: pagination.value.current,
            size: pagination.value.pageSize
          });
        }
      });
    };



    onMounted(() => {
      handleQuery({
        page: 1,
        size: pagination.value.pageSize,
      });
    });

    return {
      tags,
      pagination,
      columns,
      loading,
      handleTableChange,
      param,
      tagReq,

      tag,
      modalVisible,
      modalLoading,
      handleModalOk,
      handleQuery,


      edit,
      add,
      del
    }
  }
});
</script>