<template>
  <a-layout>
    <a-layout-content
        :style="{ background: '#fff', padding: '24px', margin: 0, minHeight: '280px' }"
    >
      <p>
        <a-form
            layout="inline"
            :model="param"
        >
          <a-form-item>
            <a-input v-model:value="param.name" placeholder="名称"></a-input>
          </a-form-item>
          <a-form-item>
            <a-button
                type="primary"
                :disabled="param.name === ''"
                @click="handleQuery({page: 1,size: pagination.pageSize})"
                size="large"
            >
              查询
            </a-button>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="add()" size="large">
              新增
            </a-button>
          </a-form-item>
          <a-form-item>
<!--            <a-input v-model:value="param.title" placeholder="标签"></a-input>-->
            <a-select
                ref="select"
                v-model:value="param.title"
                placeholder="标签选择"
            >
              <a-select-option v-for="t in tagList" :key="t.tagId" :value="t.title">
                {{t.title}}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-button
                type="primary"
                @click="handleTagQuery({page: 1,size: pagination.pageSize})"
                size="large"
            >
              查询
            </a-button>
          </a-form-item>
          <a-form-item>
            <a-range-picker
                :show-time="{ format: 'HH:mm' }"
                format="YYYY-MM-DD HH:mm"
                :placeholder="['开始时间', '结束时间']"
                @change="onRangeChange"
                @ok="onRangeOk"
            />
          </a-form-item>
          <a-button
              type="primary"
              @click="handleTimeQuery({page: 1,size: pagination.pageSize})"
              size="large"
          >
            查询
          </a-button>
        </a-form>
      </p>
      <a-table
          :columns="columns"
          :row-key="record => record.id"
          :data-source="ebooks"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
      >
        <template #cover="{ text: cover }">
          <img v-if="cover" :src="cover" alt="avatar" :width="60"/>
        </template>
        <template v-slot:category="{ text: record }">
          <span>{{ getCategoryName(record.category1Id) }} / {{ getCategoryName(record.category2Id) }}</span>
        </template>
        <template v-slot:action="{ text, record }">
          <a-space size="small">
            <a-button type="primary" @click="editTag(record)">
              标签修改
            </a-button>
<!--            <a-button class="why" type="primary" @click="setTag(record)">-->
<!--              电子书标签-->
<!--            </a-button>-->
            <router-link  :to="'/admin/doc?ebookId=' + record.id">
              <a-button type="primary">
                文档管理
              </a-button>
            </router-link>
            <a-button type="primary" @click="edit(record)">
              编辑
            </a-button>
<!--            <a-button danger @click="tagEdit(record)">-->
<!--              标签删除-->
<!--            </a-button>-->
            <a-popconfirm
                title="删除后不可恢复，确认删除？"
                ok-text="是"
                cancel-text="否"
                @confirm="del(record.id)"
            >
              <a-button danger>
                删除
              </a-button>
            </a-popconfirm>
          </a-space>
        </template>
      </a-table>
    </a-layout-content>
  </a-layout>
  <a-modal v-model:open="modalVisible"
           title="电子书表单"
           :confirm-loading="modalLoading"
           @ok="handleModalOk">
    <a-form :model="ebook" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
      <a-form-item label="封面">
        <a-input v-model:value="ebook.cover" />
      </a-form-item>
      <a-form-item label="名称">
        <a-input v-model:value="ebook.name" />
      </a-form-item>
      <a-form-item label="分类">
        <a-cascader
            v-model:value="categoryIds"
            :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
            :options="level1"
        />
      </a-form-item>
      <a-form-item label="描述">
        <a-input v-model:value="ebook.description" type="textarea"/>
      </a-form-item>
    </a-form>
  </a-modal>

  <a-modal v-model:open="tagModalVisible"
           title="用户标签"
           :confirm-loading="tagModalLoading"
           @ok="handleTagModalOk">
    <a-form :model="tag" :label-col= "{ span: 6 }" :wrapper-col="{ span: 18 }">
      <a-form-item label="标签">
        <!--          <a-checkbox-group v-model:value="tagList.title" style="width: 100%">-->
        <!--            <a-row>-->
        <!--                <a-checkbox value="A">Test</a-checkbox>-->
        <!--                <a-checkbox v-for="t in tagList" :key="t.tagId" :value="t.tagId">-->
        <!--                  {{t.title}}-->
        <!--                </a-checkbox>-->
        <!--            </a-row>-->
        <!--          </a-checkbox-group>-->

        <a-select
            ref="select"
            v-model:value="tag"
        >
          <a-select-option v-for="t in tagList" :key="t.tagId" :value="t.tagId">
            {{t.title}}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>

  <a-modal v-model:open="tagDelModalVisible"
           title="删除标签"
           :confirm-loading="tagDelModalLoading"
           @ok="ebookTagDel">
    <a-form :model="tag" :label-col= "{ span: 6 }" :wrapper-col="{ span: 18 }">
      <a-form-item label="添加标签">
        <a-select
            ref="select"
            v-model:value="tag"
        >
          <a-select-option v-for="t in ebookTag" :key="t.tagId" :value="t.tagId">
            {{t.title}}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>

  <a-modal v-model:open="tagEditModalVisible"
           title="电子书标签"
           :confirm-loading="tagEditModalLoading"
           @ok="handleTagEditModalOk">
    <a-form v-model="tagIdList.ids" :label-col= "{ span: 6 }" :wrapper-col="{ span: 18 }">
      <a-form-item label="标签设置">
        <a-select
            v-model:value="tagIdList.ids"
            mode="multiple"
        >
          <a-select-option v-for="t in tagList" :key="t.tagId" :value="t.tagId">
            {{t.title}}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>


<script lang="ts">
import {defineComponent, onMounted, reactive, ref} from 'vue';
import axios from 'axios';
import { message } from "ant-design-vue";
import {Tool} from "../../../util/tool";
import { Dayjs } from 'dayjs';

export default defineComponent({
  name: 'AdminEbook',
  setup() {
    const param = ref();
    param.value = {};
    const ebooks = ref();
    const pagination = ref({
      current: 1,
      pageSize: 4,
      total: 0
    });
    const loading = ref(false);

    const columns = [
      {
        title: '封面',
        dataIndex: 'cover',
        slots: { customRender: 'cover'}
      },
      {
        title: '名字',
        dataIndex: 'name',
      },
      {
        title: '分类',
        slots: { customRender: 'category' }
      },
      {
        title: '创建时间',
        dataIndex: 'createTime',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        slots: { customRender: 'action' }
      }
    ];

    /**
     * 数据查询
     */
    const handleQuery = (params: any) => {
      loading.value = true;
      // ebooks.value = [];
      axios.get("/ebook/list", {
        params:{
          page: params.page,
          size: params.size,
          name: param.value.name
        }
      }).then((response) => {
        loading.value = false;
        const data = response.data;
        if(data.success) {
          param.value = {};
          ebooks.value = data.content.list;

          // 重置分页按钮
          pagination.value.current = params.page;
          pagination.value.total = data.content.total;
        }else{
          message.error(data.message);
        }
      });
    };

    /**
     * 表格点击页码时触发
     */
    const handleTableChange = (pagination: any) => {
      // console.log("看看自带的分页参数都有啥；" + pagination);
      handleQuery({
        page: pagination.current,
        size: pagination.pageSize
      });
    };

    // -------- 表单 --------
    const categoryIds = ref();
    const ebook = ref();
    const modalVisible = ref<boolean>(false);
    const modalLoading = ref<boolean>(false);
    const handleModalOk = () => {
      modalLoading.value = true;
      ebook.value.category1Id = categoryIds.value[0];
      ebook.value.category2Id = categoryIds.value[1];

      axios.post("/ebook/save", ebook.value).then((response) => {
        modalLoading.value = false;
        const data = response.data; // data = commonResp
        if (data.success) {
          modalVisible.value = false;

          // 重新查询
          handleQuery({
            page: pagination.value.current,
            size: pagination.value.pageSize
          });
        }else {
          message.error(data.message);
        }
      });
    };

    const edit = (record: any) => {
      modalVisible.value = true;
      ebook.value = Tool.copy(record);
      categoryIds.value = [ebook.value.category1Id, ebook.value.category2Id];
    };

    const add = () => {
      modalVisible.value = true;
      ebook.value = {};
    };

    const del = (id: number) => {
      // console.log(id);
      axios.delete("/ebook/delete/" + id).then((response) => {
        const data = response.data; // data = commonResp
        if (data.success) {
          // 重新查询
          handleQuery({
            page: pagination.value.current,
            size: pagination.value.pageSize
          });
        }
      });
    };

    const level1 = ref();
    let categorys: any;

    const handleQueryCategory = () => {
      loading.value = true;
      axios.get("/category/all").then((response) => {
        loading.value = false;
        const data = response.data;
        if (data.success) {
          categorys = data.content;
          // console.log("原始数组", categorys);

          level1.value = [];
          level1.value = Tool.array2Tree(categorys, 0);
          // console.log("树形数组", level1.value);

          handleQuery({
            page: 1,
            size: pagination.value.pageSize
          });
        } else {
          message.error(data.message);
        }
      });
    };

    const getCategoryName = (cid: number) => {
      let result = "";
      categorys.forEach((item: any) => {
        if (item.id === cid) {
          result = item.name;
        }
      });
      return result;
    };


    const setTag = (record: any) => {
      tagQuery();
      tagModalVisible.value = true;
      ebook.value = Tool.copy(record);
    };

    // -------- 标签查询 --------
    const tagList = ref();
    const tagQuery = () => {
      tagList.value = [];
      axios.get("/tag/all").then((response) => {
        const data = response.data;
        if(data.success) {
          tags.value = data.content;
          tagList.value = data.content;
          // console.log("标签组：", tagList.value);
        }else{
          message.error(data.message);
        }
      });
    };


    // -------- 添加标签 --------
    const tag = ref();
    const tags = ref();
    let relevance = reactive({
      tagId: '0',
      ebookId: '0',
    });
    const tagModalVisible = ref<boolean>(false);
    const tagModalLoading = ref<boolean>(false);
    const handleTagModalOk = () => {
      tagModalLoading.value = true;
      console.log("标签是否为空：", tag.value==null );
      if (tag.value==null) {
        tagModalVisible.value = false;
        tagModalLoading.value = false;
        return 0;
      }

      // console.log("添加标签：", tag.value);
      relevance.tagId = tag.value;
      // console.log("电子书：", ebook.value);
      relevance.ebookId = ebook.value.id;
      // console.log("标签：", JSON.parse(JSON.stringify(relevance)));
      axios.post("/tag/ebook", JSON.parse(JSON.stringify(relevance))).then((response) => {
      //   axios.post("/tag/ebook", tag.value).then((response) => {
      //     tagModalLoading.value = false;
        const data = response.data; // data = commonResp
        if (data.success) {
          tagModalVisible.value = false;
          tagModalLoading.value = false;
        }else {
          message.error(data.message);
        }
      });
    };


    /**
     * 根据标签查询
     */
    const handleTagQuery = (params: any) => {
      loading.value = true;

      axios.get("/tag/ebook", {
        params:{
          page: params.page,
          size: params.size,
          title: param.value.title
        }
      }).then((response) => {
        loading.value = false;
        const data = response.data;
        if(data.success) {
          ebooks.value = data.content.list;

          // 重置分页按钮
          pagination.value.current = params.page;
          pagination.value.total = data.content.total;
        }else{
          message.error(data.message);
        }
      });
    };

    /**
     * 根据电子书查询标签
     */
    const tagIdList = reactive({ids: [] as any});
    const theEbook = ref();
    const ebookTag = ref();
    const tagDelModalVisible = ref<boolean>(false);
    const tagDelModalLoading = ref<boolean>(false);
    const ebookTagQuery = () => {
      ebookTag.value = [];

      axios.get("/ebook/tag/" + theEbook.value.id).then((response) => {
        const data = response.data;
        if(data.success) {
          ebookTag.value = data.content;
          // console.log("标签组：", ebookTag.value);
          for (let i = 0; i < ebookTag.value.length; i++) {
            tagIdList.ids.push(ebookTag.value[i].tagId);
          }
        }else{
          message.error(data.message);
        }
      });
    };


    const tagEdit = (record: any) => {
      tag.value = {};
      theEbook.value = Tool.copy(record);
      // console.log("用户id：", theEbook.value.id)
      ebookTagQuery();
      tagDelModalVisible.value = true;
    }

    const ebookTagDel = () => {
      axios.delete("/tag/ebookDel", {
        params:{
          tagId: tag.value,
          ebookId: theEbook.value.id,
        }
      }).then((response) => {
        const data = response.data;
        if(data.success) {
          tag.value = {};
          ebookTagQuery();
        }else{
          message.error(data.message);
        }
      });
      tagDelModalVisible.value = false;
    }



    const time = ref();
    const onRangeChange = (value: [Dayjs, Dayjs], dateString: [string, string]) => {
      time.value = value
      // console.log('Time: ', time.value);
      // console.log('Selected Time: ', value);
      // console.log('Formatted Selected Time: ', dateString);
      // console.log('Start Time: ', time.value[0].format('YYYY-MM-DD HH:mm:ss'))
      // console.log('Formatted Selected Time: ', value[0].format('YYYY-MM-DD HH:mm:ss'), ' - ', value[1].format('YYYY-MM-DD HH:mm:ss'))
    };

    const onRangeOk = (value: [Dayjs, Dayjs]) => {
      // console.log('onOk: ', value);
    };
    /**
     * 根据创建时间查询
     */
    const handleTimeQuery = (params: any) => {
      loading.value = true;

      axios.get("/ebook/time", {
        params:{
          page: params.page,
          size: params.size,
          startTime: time.value[0].format('YYYY-MM-DD HH:mm:ss'),
          endTime: time.value[1].format('YYYY-MM-DD HH:mm:ss')
        }
      }).then((response) => {
        loading.value = false;
        const data = response.data;
        if(data.success) {
          param.value = {};
          ebooks.value = data.content.list;

          // 重置分页按钮
          pagination.value.current = params.page;
          pagination.value.total = data.content.total;
        }else{
          message.error(data.message);
        }
      });
    };


    const tagEditModalVisible = ref<boolean>(false);
    const tagEditModalLoading = ref<boolean>(false);
    const editTag = (record: any) => {
      tagIdList.ids = [];
      theEbook.value = Tool.copy(record);
      ebookTagQuery();
      tagEditModalVisible.value = true;
    };

    const handleTagEditModalOk = (params: any) => {
      tagEditModalLoading.value = true;
      console.log("标签是否为空：", tagIdList.ids.length==0);
      if (tagIdList.ids.length==0) {
        axios.delete("/tag/ebook/delAll/"+theEbook.value.id).then((response) => {
          tagEditModalLoading.value = false;
          const data = response.data; // data = commonResp
          if (data.success) {
            tagEditModalVisible.value = false;
          }else {
            message.error(data.message);
          }
        });
        return 0;
      }
      console.log("电子书id：", theEbook.value.id);
      console.log("添加标签：", tagIdList.ids);
      axios.post("tag/ebook/edit", {
        ebookId: theEbook.value.id,
        tagIds: tagIdList.ids,
      }).then((response) => {
        const data = response.data; // data = commonResp
        if (data.success) {
          tagEditModalVisible.value = false;
          tagEditModalLoading.value = false;
        }else {
          message.error(data.message);
        }
      });
    };


    onMounted(() => {
      handleQueryCategory();
      tagQuery();
    });

    return {
      ebooks,
      pagination,
      columns,
      loading,
      handleTableChange,
      param,

      ebook,
      modalVisible,
      modalLoading,
      handleModalOk,
      handleQuery,
      getCategoryName,
      level1,
      categoryIds,

      tagList,
      tag,
      tagModalVisible,
      tagModalLoading,
      handleTagModalOk,
      handleTagQuery,
      setTag,
      ebookTagQuery,
      tagEdit,
      tagDelModalVisible,
      tagDelModalLoading,
      ebookTag,
      ebookTagDel,

      edit,
      add,
      del,

      onRangeChange,
      onRangeOk,
      handleTimeQuery,

      tagEditModalVisible,
      tagEditModalLoading,
      handleTagEditModalOk,
      editTag,
      tagIdList
    }
  }
});
</script>
