<template>
  <a-layout>
    <a-layout-content
        :style="{ background: '#fff', padding: '24px', margin: 0, minHeight: '280px' }"
    >
      <p>
        <a-form
            layout="inline"
            :model="param"
        >
          <a-form-item>
            <a-input v-model:value="param.loginName" placeholder="用户名"></a-input>
          </a-form-item>
          <a-form-item>
            <a-button
                type="primary"
                :disabled="param.name === ''"
                @click="handleQuery({page: 1,size: pagination.pageSize})"
                size="large"
            >
              查询
            </a-button>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="add()" size="large">
              新增
            </a-button>
          </a-form-item>
          <a-form-item>
            <!--            <a-input v-model:value="param.title" placeholder="标签"></a-input>-->
            <a-select
                ref="select"
                v-model:value="param.title"
                placeholder="标签选择"
            >
              <a-select-option v-for="t in tagList" :key="t.tagId" :value="t.title">
                {{t.title}}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-button
                type="primary"
                @click="handleTagQuery({page: 1,size: pagination.pageSize})"
                size="large"
            >
              查询
            </a-button>
          </a-form-item>
        </a-form>
      </p>
      <a-table
          :columns="columns"
          :row-key="record => record.id"
          :data-source="users"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
      >
<!--        <template #cover="{ text: cover }">-->
<!--          <img v-if="cover" :src="cover" alt="avatar" :width="60"/>-->
<!--        </template>-->
<!--        <template v-slot:category="{ text: record }">-->
<!--          <span>{{ getCategoryName(record.category1Id) }} / {{ getCategoryName(record.category2Id) }}</span>-->
<!--        </template>-->
        <template v-slot:action="{ text, record }">
          <a-space size="small">
            <a-button type="primary" @click="editTag(record)">
              标签修改
            </a-button>
<!--            <a-button type="primary" @click="setTag(record)">-->
<!--              用户标签-->
<!--            </a-button>-->
            <a-button type="primary" @click="resetPassword(record)">
              重置密码
            </a-button>
            <a-button type="primary" @click="edit(record)">
              编辑
            </a-button>
<!--            <a-button danger @click="tagEdit(record)">-->
<!--              标签删除-->
<!--            </a-button>-->
            <a-popconfirm
                title="删除后不可恢复，确认删除？"
                ok-text="是"
                cancel-text="否"
                @confirm="del(record.id)"
            >
              <a-button danger>
                删除
              </a-button>
            </a-popconfirm>
          </a-space>
        </template>
      </a-table>
    </a-layout-content>
  </a-layout>
  <a-modal v-model:open="modalVisible"
           title="用户表单"
           :confirm-loading="modalLoading"
           @ok="handleModalOk">
    <a-form :model="user" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
      <a-form-item label="用户名">
        <a-input v-model:value="user.loginName" :disabled="!!user.id"/>
      </a-form-item>
      <a-form-item label="昵称">
        <a-input v-model:value="user.name" />
      </a-form-item>
      <a-form-item label="密码" v-show="!user.id">
        <a-input v-model:value="user.password"/>
      </a-form-item>
    </a-form>
  </a-modal>

  <a-modal v-model:open="resetModalVisible"
           title="重置密码"
           :confirm-loading="resetModalLoading"
           @ok="handleResetModalOk">
    <a-form :model="user" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
      <a-form-item label="新密码">
        <a-input v-model:value="user.password"/>
      </a-form-item>
    </a-form>
  </a-modal>

    <a-modal v-model:open="tagEditModalVisible"
             title="用户标签"
             :confirm-loading="tagEditModalLoading"
             @ok="handleTagEditModalOk">
      <a-form v-model="tagIdList.ids" :label-col= "{ span: 6 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="标签设置">
          <a-select
              v-model:value="tagIdList.ids"
              mode="multiple"
          >
            <a-select-option v-for="t in tagList" :key="t.tagId" :value="t.tagId">
              {{t.title}}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>


<!--    <a-modal v-model:open="tagModalVisible"-->
<!--             title="用户标签"-->
<!--             :confirm-loading="tagModalLoading"-->
<!--             @ok="handleTagModalOk">-->
<!--      <a-form :model="tagIdList" :label-col= "{ span: 6 }" :wrapper-col="{ span: 18 }">-->
<!--        <a-form-item label="添加标签">-->
<!--          <a-checkbox-group style="width: 100%">-->
<!--            <a-row>-->
<!--              <a-checkbox v-for="t in tagList" :key="t.tagId" :value="t.tagId">-->
<!--                {{t.title}}-->
<!--              </a-checkbox>-->
<!--            </a-row>-->
<!--          </a-checkbox-group>-->
<!--        </a-form-item>-->
<!--      </a-form>-->
<!--    </a-modal>-->

<!--    <a-modal v-model:open="tagDelModalVisible"-->
<!--             title="删除标签"-->
<!--             :confirm-loading="tagDelModalLoading"-->
<!--             @ok="userTagDel">-->
<!--      <a-form :model="tag" :label-col= "{ span: 6 }" :wrapper-col="{ span: 18 }">-->
<!--        <a-form-item label="删除标签">-->
<!--            <a-select-->
<!--                ref="select"-->
<!--                v-model:value="tag"-->
<!--            >-->
<!--              <a-select-option v-for="t in userTag" :key="t.tagId" :value="t.tagId">-->
<!--                {{t.title}}-->
<!--              </a-select-option>-->
<!--            </a-select>-->
<!--        </a-form-item>-->
<!--      </a-form>-->
<!--    </a-modal>-->

</template>




<script lang="ts">
import { defineComponent,onMounted,ref,reactive } from 'vue';
import axios from 'axios';
import { message } from "ant-design-vue";
import {Tool} from "../../../util/tool";
import {forEach} from "wangeditor/dist/utils/util";

declare let hexMd5: any;
declare let KEY: any;



export default defineComponent({
  name: 'AdminUser',
  setup() {
    const param = ref();
    param.value = {};
    const users = ref();
    const tags = ref();
    const pagination = ref({
      current: 1,
      pageSize: 4,
      total: 0
    });
    const loading = ref(false);

    const columns = [
      {
        title: '用户名',
        dataIndex: 'loginName',
      },
      {
        title: '昵称',
        dataIndex: 'name',
      },
      {
        title: '密码',
        dataIndex: 'password',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        slots: { customRender: 'action' }
      }
    ];

    /**
     * 数据查询
     */
    const handleQuery = (params: any) => {
      loading.value = true;
      // users.value = [];
      axios.get("/user/list", {
        params:{
          page: params.page,
          size: params.size,
          loginName: param.value.loginName,
        }
      }).then((response) => {
        loading.value = false;
        const data = response.data;
        if(data.success) {
          param.value = {};
          users.value = data.content.list;

          // 重置分页按钮
          pagination.value.current = params.page;
          pagination.value.total = data.content.total;
        }else{
          message.error(data.message);
        }
      });
    };

    /**
     * 表格点击页码时触发
     */
    const handleTableChange = (pagination: any) => {
      // console.log("看看自带的分页参数都有啥；" + pagination);
      handleQuery({
        page: pagination.current,
        size: pagination.pageSize
      });
    };

    // -------- 表单 --------
    const user = ref();
    const modalVisible = ref<boolean>(false);
    const modalLoading = ref<boolean>(false);
    const handleModalOk = () => {
      modalLoading.value = true;

      user.value.password = hexMd5(user.value.password + KEY);

      axios.post("/user/save", user.value).then((response) => {
        modalLoading.value = false;
        const data = response.data; // data = commonResp
        if (data.success) {
          modalVisible.value = false;

          // 重新查询
          handleQuery({
            page: pagination.value.current,
            size: pagination.value.pageSize
          });
        }else {
          message.error(data.message);
        }
      });
    };

    // const setTag = (record: any) => {
    //   tag.value = {};
    //   theUser.value = Tool.copy(record);
    //   userTagQuery();
    //   tagQuery();
    //   tagModalVisible.value = true;
    //   user.value = Tool.copy(record);
    // };

    // -------- 标签查询 --------
    const tagList = ref();
    const tagQuery = () => {
      tagList.value = [];
      axios.get("/tag/all").then((response) => {
        const data = response.data;
        if(data.success) {
          tags.value = data.content;
          tagList.value = data.content;
          // console.log("标签组：", tagList.value);
        }else{
          message.error(data.message);
        }
      });
    };


    // -------- 添加标签 --------
    const tag = ref();
    let relevance = reactive({
      tagId: '0',
      userId: '0',
    });
    const tagModalVisible = ref<boolean>(false);
    const tagModalLoading = ref<boolean>(false);
    const handleTagModalOk = () => {
      tagModalLoading.value = true;
      console.log("标签是否为空：", tag.value==null );
      if (tag.value==null) {
        tagModalVisible.value = false;
        tagModalLoading.value = false;
        return 0;
      }
      console.log("添加标签：", tag.value);
      relevance.tagId = tag.value;
      // console.log("用户：", user.value);
      relevance.userId = user.value.id;
      // console.log("标签：", JSON.parse(JSON.stringify(relevance)));
      // axios.post("/tag/user", JSON.parse(JSON.stringify(relevance))).then((response) => {
      //   // axios.post("/tag/user", tag.value).then((response) => {
      //   //   tagModalLoading.value = false;
      //   const data = response.data; // data = commonResp
      //   if (data.success) {
          tagModalVisible.value = false;
          tagModalLoading.value = false;
      //   }else {
      //     message.error(data.message);
      //   }
      // });
    };

    /**
     * 根据标签查询
     */
    const handleTagQuery = (params: any) => {
      loading.value = true;
      axios.get("/tag/user", {
        params:{
          page: params.page,
          size: params.size,
          title: param.value.title,
        }
      }).then((response) => {
        loading.value = false;
        const data = response.data;
        if(data.success) {
          users.value = data.content.list;

          // 重置分页按钮
          pagination.value.current = params.page;
          pagination.value.total = data.content.total;
        }else{
          message.error(data.message);
        }
      });
    };

    /**
     * 根据用户查询标签
     */
    const tagIdList = reactive({ids: [] as any});
    const theUser = ref();
    const userTag = ref();
    const tagDelModalVisible = ref<boolean>(false);
    const tagDelModalLoading = ref<boolean>(false);
    const userTagQuery = () => {
      userTag.value = [];

      axios.get("/user/tag/" + theUser.value.id).then((response) => {
        const data = response.data;
        if(data.success) {
          userTag.value = data.content;
          // console.log("标签组：", userTag.value);
          // userTag.value.forEach((item: any) => {
          //   tagIdList.ids.push(userTag.value[item].tagId);
          // })
          for (let i = 0; i < userTag.value.length; i++) {
            tagIdList.ids.push(userTag.value[i].tagId);
          }
        }else{
          message.error(data.message);
        }
        // console.log("标签：", tagIdList.ids);
      });
    };


    const tagEdit = (record: any) => {
      tag.value = {};
      theUser.value = Tool.copy(record);
      // console.log("用户id：", theUser.value.id)
      userTagQuery();
      tagDelModalVisible.value = true;
  }

  //   const userTagDel = () => {
  //     // let req = reactive({
  //     //   tagId: tag.value,
  //     //   userId: theUser.value.id,
  //     // });
  //     // console.log("删除标签：", req);
  //     // console.log("删除标签：", JSON.parse(JSON.stringify(req)));
  //     // axios.delete("/tag/userDel", JSON.parse(JSON.stringify(req))).then((response) => {
  //     axios.delete("/tag/userDel", {
  //       params:{
  //         tagId: tag.value,
  //         userId: theUser.value.id,
  //       }
  //     }).then((response) => {
  //       const data = response.data;
  //       if(data.success) {
  //         tag.value = {};
  //         userTagQuery();
  //       }else{
  //         message.error(data.message);
  //       }
  //     });
  //     // console.log("用户id：", theUser.value.id);
  //     // console.log("删除标签id：", tag.value);
  //     tagDelModalVisible.value = false;
  // }

    const edit = (record: any) => {
      modalVisible.value = true;
      user.value = Tool.copy(record);
    };

    const add = () => {
      modalVisible.value = true;
      user.value = {};
    };

    const del = (id: number) => {
      // console.log(id);
      axios.delete("/user/delete/" + id).then((response) => {
        const data = response.data; // data = commonResp
        if (data.success) {
          // 重新查询
          handleQuery({
            page: pagination.value.current,
            size: pagination.value.pageSize
          });
        }
      });
    };

    const level1 = ref();
    let categorys: any;

    const getCategoryName = (cid: number) => {
      let result = "";
      categorys.forEach((item: any) => {
        if (item.id === cid) {
          result = item.name;
        }
      });
      return result;
    };

    // -------- 表单 --------
    const resetModalVisible = ref<boolean>(false);
    const resetModalLoading = ref<boolean>(false);
    const handleResetModalOk = () => {
      resetModalLoading.value = true;

      user.value.password = hexMd5(user.value.password + KEY);

      axios.post("/user/resetPassword", user.value).then((response) => {
        resetModalLoading.value = false;
        const data = response.data; // data = commonResp
        if (data.success) {
          resetModalVisible.value = false;

          // 重新查询
          handleQuery({
            page: pagination.value.current,
            size: pagination.value.pageSize
          });
        }else {
          message.error(data.message);
        }
      });
    };

    // 重置密码
    const resetPassword = (record: any) => {
      resetModalVisible.value = true;
      user.value = Tool.copy(record);
      user.value.password = null;
    };

    const tagEditModalVisible = ref<boolean>(false);
    const tagEditModalLoading = ref<boolean>(false);
    const editTag = (record: any) => {
      tagIdList.ids = [];
      theUser.value = Tool.copy(record);
      userTagQuery();
      tagEditModalVisible.value = true;
    };

    const handleTagEditModalOk = (params: any) => {
      tagEditModalLoading.value = true;
      console.log("标签是否为空：", tagIdList.ids.length==0);
      if (tagIdList.ids.length==0) {
        axios.delete("/tag/user/delAll/"+theUser.value.id).then((response) => {
          tagEditModalLoading.value = false;
          const data = response.data; // data = commonResp
          if (data.success) {
            tagEditModalVisible.value = false;
          }else {
            message.error(data.message);
          }
        });
        return 0;
      }
      console.log("用户id：", theUser.value.id);
      console.log("添加标签：", tagIdList.ids);
      axios.post("tag/user/edit", {
          userId: theUser.value.id,
          tagIds: tagIdList.ids,
      }).then((response) => {
        const data = response.data; // data = commonResp
        if (data.success) {
          tagEditModalVisible.value = false;
          tagEditModalLoading.value = false;
        }else {
          message.error(data.message);
        }
      });
    };

    onMounted(() => {
      handleQuery({
        page: 1,
        size: pagination.value.pageSize,
      });
      tagQuery();
    });

    return {
      users,
      pagination,
      columns,
      loading,
      handleTableChange,
      param,

      user,
      modalVisible,
      modalLoading,
      handleModalOk,
      handleQuery,
      getCategoryName,
      level1,

      resetModalVisible,
      resetModalLoading,
      handleResetModalOk,
      resetPassword,

      tag,
      tagModalVisible,
      tagModalLoading,
      handleTagModalOk,
      handleTagQuery,
      tagList,
      userTagQuery,
      tagEdit,
      tagDelModalVisible,
      tagDelModalLoading,
      userTag,


      edit,
      add,
      del,

      tagIdList,
      editTag,
      tagEditModalVisible,
      tagEditModalLoading,
      handleTagEditModalOk,
    }
  }
});
</script>