<template>
  <a-layout-footer style="text-align: center">
    <span v-show="user.id">欢迎：{{user.name}}</span>
    Wiki ©2024 Created by JamYoung
  </a-layout-footer>
</template>

<script  lang="ts">
import { defineComponent, computed } from 'vue';
import store from "@/store";

export default defineComponent({
  name: 'theFooter',
  setup() {
    const user = computed( () => store.state.user);

    return {
      user
    }
  }
});
</script>